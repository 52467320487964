html {
  background-color: #394654 !important;
}

* {
  box-sizing: border-box;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

::selection {
  background-color: #d72638;
  color: #fcecc9;
}

a {
  color: inherit;
  text-decoration: none;
}

a.disabled,
a.disabled:hover {
  color: rgba(0, 0, 0, 0.1) !important;
  cursor: not-allowed;
  pointer-events: none;
}

a.email:hover {
  color: rgba(255, 255, 255, 0.7);
}

.credit {
  color: rgba(255, 255, 255, 0.5);
}

body {
  font-family: 'Ideal Sans SSm A', 'Ideal Sans SSm B';
  font-style: normal;
  font-weight: 300;
  font-size: 1.5625em;
  line-height: 1.6em;
}

.centre {
  text-align: center;
}

.index {
  background-color: #394654 !important;
  padding-bottom: 1.5em !important;
}

.top {
  background-color: #fcecc9;
  padding: 0px 20px 0px 20px;
  padding-top: 0.8em;
  padding-bottom: 2.8em;
}

.bottom {
  background-color: #394654;
  color: #fcecc9;
  padding-bottom: 0.8em;
}

.top > div,
.bottom > div {
  max-width: 1040px;
}

/* COMPONENTS */

.mast {
  padding-top: 1.4em;
}

.ringside {
  font-family: 'Peristyle A', 'Peristyle B' !important;
  font-weight: 800 !important;
}

.masthead {
  padding-bottom: 0.525em;
  font-size: 2em;
  line-height: 1;
  text-align: center;

  text-transform: uppercase;
  color: #ffffff;
}

.masthead:hover {
  cursor: pointer;
}

.links {
  padding-top: 1.6em;
}

.link {
  padding-bottom: 0.2em;
  font-size: 4em;
  line-height: 1em;
  text-align: center !important;

  text-transform: uppercase;
  color: #fcecc9;
}

.link + .link {
  padding-top: 0.6em;
}

.link a:hover {
  color: #d72638;
}

.masthead:hover {
  cursor: pointer;
}

.hover:hover {
  background-color: #394654;
  margin: -5px -25px -5px -25px;
  padding: 5px 25px 5px 25px;
}

.byline {
  margin: 0 auto;
  text-align: center;
  color: #394654;
  font-size: 2em;
  line-height: 2;
}

.content {
  padding-top: 1.6em;
  padding-left: 50px;
  padding-right: 50px;
  color: #394654;
}

.section {
  font-size: 2em !important;
  line-height: 2em;
  text-align: center !important;
  color: #d72638;
  font-family: 'Peristyle A', 'Peristyle B' !important;
  font-weight: 800 !important;
}

p + p {
  padding-top: 1.6em;
}

p + div {
  padding-top: 0.8em;
}

.divider {
  font-weight: 400;
  text-align: center;
  color: #d72638;
  font-size: 6em;
  line-height: 0.5;
  padding-top: 0em;
  margin-top: 0.8em;
  margin-bottom: 0.3em;
}

.divider + p {
  padding-top: 0em;
}

.title {
  color: #d72638;
  font-size: 6em;
  line-height: 1em;
  padding-top: 0.2em;
  text-align: center;
}

.uc {
  text-transform: uppercase;
}

.tc {
  text-transform: none !important;
}

.link .tc {
  color: rgba(255, 255, 255, 0.4) !important;
}

.sc {
  font-family: 'Ideal Sans SSm SC A', 'Ideal Sans SSm SC B';
}

.bio {
  padding-top: 2.6em;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 2.3em;

  font-family: 'Ideal Sans SSm A', 'Ideal Sans SSm B';
  font-weight: 400 !important;
  font-size: 20px;
  line-height: 2em;
}

.navigation {
  padding-top: 0.3em;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 2.6em;

  font-family: 'Ideal Sans SSm A', 'Ideal Sans SSm B';
  font-weight: 400 !important;
  font-size: 20px;
  line-height: 2em;
  display: flex;
  justify-content: space-between;
}

.navigation a {
  margin: 0;
  font-size: 2em;

  text-transform: uppercase;
  color: #fcecc9;
}

.navigation a:hover {
  color: #d72638;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 1em;
    line-height: 1.5;
  }

  p + p {
    padding-top: 1.5em;
  }

  .divider {
    margin-bottom: 0;
  }

  .divider + p {
    padding-top: 0.8em;
  }

  .bio {
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 2em;
    margin-top: -0.25em;
  }

  .navigation {
    padding-left: 20px;
    padding-right: 20px;
  }

  .byline {
    padding-top: 0.6em;
    padding-left: 10px;
  }

  .contrast {
    margin: 0px -10px 0px -10px;
    padding: 0px 10px 0px 10px;
  }

  .navigation {
    padding-top: 1.25em;
  }

  .mast {
    padding-top: 1.2em;
    padding-bottom: 0.6em;
  }

  .content {
    padding-top: 2.1em;
    padding-bottom: 0em;
    padding-left: 0px;
    padding-right: 0px;
    font-family: 'Ideal Sans SSm A', 'Ideal Sans SSm B';
    font-weight: 300;
  }

  .title {
    font-size: 3em;
    line-height: 1;
  }

  .byline {
    font-size: 1.5em;
    line-height: 1.5em;
  }

  .masthead {
    font-size: 2em;
  }
}
